<template>
    <div class="project-sidebar">
        <div class="row">
            <div class="col-md-7">
                <img class="img-sidebar" src="@/assets/img/projects/project-sidebar.webp" alt="">
            </div>
            <div class="col-md-5">
                <h3>Level One</h3>
                <h6>The Prerequisites</h6>
            </div>
            <p class="mb-4">Let's first confirm that you have the strength to begin your Laravel journey. A basic
                understanding of PHP, object-oriented programming, and database management are essential prerequisites.
            </p>
            <h6> Things You'll Learn </h6>
            <ul>
                <li>
                    <i class="fa-solid fa-check" style="color: #fff;"></i>
                    <span>Database Access</span>
                </li>
                <li>
                    <i class="fa-solid fa-check" style="color: #fff;"></i>

                    <span>Object-Oriented Programming</span>
                </li>
                <li>
                    <i class="fa-solid fa-check" style="color: #fff;"></i>
                    <span>Database Access</span>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
        };
    },
}
</script>